<template>
  <div
    style="
      position: sticky !important;
      top: 0;
      z-index: 100;
      background-color: #fff;
    "
  >
    <!-- search bar start-->
    <div class="container-fluid">
      <nav class="navbar2">
        <div class="container">
          <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-8 col-lg-8 col-xl-8">
              <div class="logo">
                <a href="#"
                  ><img style="width: 100px;" src="/pphlogo.png" alt="Logo" class="pphlogo mt-2" 
                /></a>
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-xl-4">
              <div class="mt-3 searchInput" :class="{ active: isSearchActive }">
                <input
                  v-model="searchQuery"
                  v-on:keyup.enter="GoToListWithFilter('', '', '', searchQuery)"
                  type="text"
                  placeholder="Search.."
                />
                <div class="resultBox">
                  <ul v-if="isSearchActive">
                    <li
                      v-for="(suggestion, index) in suggestions"
                      :key="index"
                      @click="GoToDetails(suggestion)"
                    >
                      <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-2">
                          <img
                            :src="suggestion.image"
                            :alt="suggestion.image"
                            style="width: 100%; width: 100%"
                          />
                        </div>
                        <div class="field col-12 md:col-10">
                          <p class="font-bold">
                            {{
                              FormatService.FormatTitle(suggestion.title, 30)
                            }}...
                          </p>
                          <p
                            v-if="
                              suggestion.actual_price < suggestion.price ||
                              suggestion.actual_price > suggestion.price
                            "
                          >
                            <b>
                              ₹
                              {{
                                FormatService.FormatAmount(suggestion.price)
                              }}</b
                            >
                            <s style="font-weight: 100; font-size: 12px">
                              ₹
                              {{
                                FormatService.FormatAmount(
                                  suggestion.actual_price
                                )
                              }}</s
                            >&nbsp;
                            <Badge v-if=" suggestion.discount && suggestion.discount > 0" style="margin-top: -10px;color: black;" :value="suggestion.discount + '% off'" severity="warning"></Badge>

                          </p>
                          <b v-else>
                            ₹
                            {{
                              FormatService.FormatAmount(suggestion.price)
                            }}</b
                          >
                          
                        
                     
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="icon" @click="GoToList()">
                  <i class="fas fa-search"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <!-- search bar end-->
    <header class="header-area mt-3 mb-3">
      <div class="container">
        <div class="row">
          <div :stickyHeaders="true">
            <nav class="navigation">
              <!---sidebar mobile view menu start-->
              <button class="mobilebtn" @click="toggleMenu()">
                <i class="fa-solid fa-bars"></i>
              </button>
              <div
                class="sidebar"
                :style="{ display: sidebarVisible ? 'flex' : 'none'  }"
              >
                <img
                  class="logo2"
                  src="pphlogo.png"
                  style=" padding-bottom: 30px; padding-left: 20px;width: 100px"
                  @click="gotohome()"
                />
                <div class="flex-shrink-0 p-3">
                  <ul class="list-unstyled ps-0">
                    <li @click="hideSidebar()" class="hidebar">
                      <i
                        class="fa-solid fa-xmark"
                        style="width: 20px; height: 20px"
                      ></i>
                    </li>
                    <li
                      class="mb-1"
                      v-for="(M1, indexM1) in products"
                      :key="indexM1"
                    >
                      <button
                        @click="toggleCollapse(indexM1)"
                        class="btn d-inline-flex align-items-center rounded border-0 toggle-button"
                        :class="{ collapsed: isCollapsed[indexM1] }"
                        data-bs-toggle="collapse"
                      >
                        <h4
                          style="color: rgb(55 55 55); font-weight: bold; font-size: 16px;"
                          @click="goTo(M1)"
                        >
                          {{ FormatService.FormatToUpperCase(M1.name) }}
                        </h4>
                      </button>
                      <button
                        v-if="
                          isCollapsed[indexM1] &&
                          (M1.result.length > 0 || M1.sub_menu.length > 0)
                        "
                        @click="toggleArrow"
                        :class="{ active: isActive }"
                        class="arrow"
                      
                      >
                        <i class="fa-solid fa-angle-right"></i>
                      </button>
                      <button
                        @click="toggleArrow"
                        :class="{ active: isActive }"
                        class="arrow"
                        v-else-if="
                          !isCollapsed[indexM1] &&
                          (M1.result.length > 0 || M1.sub_menu.length > 0)
                        "
                      >
                        <i class="fa-solid fa-chevron-down"></i>
                      </button>
                      <div :class="{ collapse: isCollapsed[indexM1] }">
                        <div v-if="M1.result.length > 0">
                          <ul
                            v-for="(M2, indexM2) in M1.result"
                            :key="indexM2"
                            class="btn-toggle-nav list-unstyled fw-normal pb-1 small"
                          >
                            <li>
                              <button
                                @click="toggleCollapse_sub(indexM2)"
                                class="btn d-inline-flex align-items-center rounded border-0 collapsed"
                                style="text-transform: lowercase"
                                :class="{ collapsed: isCollapsed_sub[indexM2] }"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                              >
                                <h5
                                  class="sub-menu"
                                  style="
                                    color: #000;
                                    font-weight: bold;
                                    text-transform: capitalize;
                                    font-size: 13px;

                                  "
                                >
                                  {{ FormatService.FormatToUpperCase(M2._id) }}
                                </h5>
                                <!-- @click="GoToListWithFilter('', M1.result[indexM2]['_id'] , products[indexM1]['name'] , '')" -->
                              </button>
                              <button
                                v-if="isCollapsed_sub[indexM2]"
                                @click="toggleArrow"
                                :class="{ active: isActive }"
                                class="arrow"
                              >
                                <i class="fa-solid fa-angle-right"></i>
                              </button>
                              <button
                                v-else
                                @click="toggleArrow"
                                :class="{ active: isActive }"
                                class="arrow"
                              >
                                <i class="fa-solid fa-chevron-down"></i>
                              </button>
                              <div
                                :class="{ collapse: isCollapsed_sub[indexM2] }"
                              >
                                <ul v-if="M2.data.length > 0">
                                  <li
                                    v-for="(M3, indexM3) in M2.data"
                                    :key="indexM3"
                                    class="btn-toggle-nav list-unstyled fw-normal pb-1 small"
                                    style="color: #000"
                                  >
                                    <!-- <router-link v-if="indexM3<=3" style="font-size: 12px; line-height: 1em; color:#000; padding-left:10px; font-size:14px" to="/listing"
                      class="item">{{M3.brand}}</router-link> -->
                                    <span
                                      @click="
                                        GoToListWithFilter(
                                          M2.data[indexM3]['brand'],
                                          M1.result[indexM2]['_id'],
                                          products[indexM1]['product_types'],
                                          ''
                                        )
                                      "
                                      v-if="indexM3 <= 3"
                                      style="
                                        font-size: 12px;
                                        line-height: 0.5rem;
                                        color: #000;
                                        padding-left: 10px;
                                        font-size: 11px;

                                      "
                                      class="item"
                                    >
                                      {{ FormatService.FormatToUpperCase(M3.brand) }}
                                    </span>
                                  </li>
                                  <li class="view" v-if="M2.data.length > 3">
                                    <b
                                      style="color: darkcyan"
                                      @click="
                                        GoToListWithFilter(
                                          '',
                                          M1.result[indexM2]['_id'],
                                          products[indexM1]['product_types'],
                                          ''
                                        )
                                      "
                                      >{{FormatService.FormatToUpperCase('View All...')}}</b
                                    >
                                    <!-- <a href="#" style="color: darkcyan;list-style: none; padding-left: 10px;">View All...</a> -->
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div v-else-if="M1.sub_menu.length > 0">
                          <ul
                            v-for="(M9, indexM9) in M1.sub_menu"
                            :key="indexM9"
                            class="btn-toggle-nav list-unstyled fw-normal pb-1 small"
                          >
                            <li>
                              <h5
                                @click="
                                  GoToListWithFilter(
                                    '',
                                    M1.sub_menu[indexM9]['name'],
                                    '',
                                    ''
                                  )
                                "
                                class="sub-menu"
                                style="
                                  color: rgb(0, 0, 0);;
                                  font-weight: 600;
                                  text-transform: lowercase;
                                  font-size: 16px;
                                "
                              >
                                {{ FormatService.FormatToUpperCase(M9.name) }}
                              </h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!---sidebar mobile view menu end-->

              <!---web view menu start-->
              <div class="menu-area" style="cursor: pointer">
                <ul class="marginauto" v-if="products">
                  <li
                    class="hideOnMobile"
                    v-for="(L1, indexL1) in products"
                    :key="indexL1"
                  >
                    <a
                      class="title"
                      style="font-weight: 600; color: black; cursor: pointer"
                      @click="goTo(L1)"
                      >{{ FormatService.FormatToUpperCase(L1.name) }}</a
                    >
                    <span
                      class="subIocn"
                      @click="ToogleSub1(indexL1)"
                      :class="{ active: subItem1 === indexL1 }"
                      v-if="L1.result.length > 0 || L1.sub_menu.length > 0"
                    ></span>
                    <ul
                      class="mega"
                      :class="{ active: subItem1 === indexL1 }"
                      v-if="L1.result.length > 0"
                    >
                      <li class="hideOnMobile">
                        <div
                          class="mega-menu"
                          style="overflow-y: scroll; height: 500px"
                        >
                          <div
                            class="mega-item"
                            v-for="(L2, indexL2) in L1.result"
                            :key="indexL2"
                          >
                            <h3
                              style="color: #000; font-weight: bold"
                              @click="
                                GoToListWithFilter(
                                  '',
                                  L1.result[indexL2]['_id'],
                                  products[indexL1]['product_types'],
                                  ''
                                )
                              "
                            >
                              {{ FormatService.FormatToUpperCase(L2._id) }}
                            </h3>
                            <ul v-if="L2.data.length > 0">
                              <li
                                class="hideOnMobile"
                                v-for="(L3, indexL3) in L2.data"
                                :key="indexL3"
                              >
                                <span
                                  @click="
                                    GoToListWithFilter(
                                      L2.data[indexL3]['brand'],
                                      L1.result[indexL2]['_id'],
                                      products[indexL1]['product_types'],
                                      ''
                                    )
                                  "
                                  v-if="indexL3 <= 3"
                                  style="font-size: 12px; line-height: 1em"
                                  class="item"
                                >
                                  {{ FormatService.FormatToUpperCase(L3.brand) }}
                                </span>
                              </li>
                              <li v-if="L2.data.length > 3">
                                <b
                                  style="color: darkcyan"
                                  @click="
                                    GoToListWithFilter(
                                      '',
                                      L1.result[indexL2]['_id'],
                                      products[indexL1]['product_types'],
                                      ''
                                    )
                                  "
                                  >{{FormatService.FormatToUpperCase('View All...')}}</b
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul
                      class="sub"
                      :class="{ active: subItem1 === indexL1 }"
                      v-else-if="L1.sub_menu.length > 0"
                    >
                      <li
                        class="mega-item"
                        v-for="(L9, indexL9) in L1.sub_menu"
                        :key="indexL9"
                      >
                        <h3
                          style="color: #000; font-weight: bold"
                          @click="
                            GoToListWithFilter(
                              '',
                              L1.sub_menu[indexL9]['name'],
                              '',
                              ''
                            )
                          "
                        >
                          {{ FormatService.FormatToUpperCase(L9.name) }}
                        </h3>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!---web view menu end-->
            </nav>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import apis from "@/apis";
import Autocomplete from "../components/Autocomplete.vue";
import FormatService from "../service/FormatService";
import Loader from "../components/Loader.vue";
export default {
  name: "PPHPphmegamenu",
  components: { Autocomplete, Loader },
  data() {
    return {
      form: {},
      loading: false,
      filters: {},
      isSearchActive: false,
      FormatService: null,
      overlayNotificationItems: [],
      suggestions: [],
      products: [],
      searchQuery: "",
      mobileMenu: false,
      subItem1: null,
      megaItem1: false,
      megaItem2: false,
      sidebarVisible: false,
      isCollapsed: [],
      isCollapsed_sub: [],
      isActive: false,
    };
  },
  mounted() {
    this.FormatService = new FormatService();
    this.GetMenu();
    // this.$nextTick(() => {
    //   const tooltipButton = this.$refs.tooltipButton;
    //   new bootstrap.Tooltip(tooltipButton);
    // });
  },
  computed: {
    arrowIcon() {
      return this.isCollapsed ? "fa-chevron-down" : "fa-chevron-up";
    },
  },
  watch: {
    searchQuery() {
      if (this.searchQuery == "" || this.searchQuery.length<3) {
        this.isSearchActive = false;
      } else {
        this.updateSuggestions();
      }
    },
  },
  methods: {
    GoToListWithFilter(brand = "", product_type = "", type = "", search = "" ) { 
      this.hideSidebar  ();
      this.isSearchActive = false;
      this.$emit("onFilter", {
        brand: brand,
        product_type: product_type,
        // type: type,
        // type:[],
        search: search,
      });
    },
    toggleArrow() {
      // Toggle the value of isActive
      this.isActive = !this.isActive;
    },
    toggleCollapse(index) {
      this.isCollapsed[index] = !this.isCollapsed[index];
    },
    toggleCollapse_sub(index) {
      this.isCollapsed_sub[index] = !this.isCollapsed_sub[index];
    },
    GoToDetails(data) {
      this.$router.push({ name: "pphprodetails", params: { id: data._id , title:data.title} });
    },
    GoToList() {
      this.$router.push({ name: "pph_listing" });
    },
    toggleMenu() {
      this.sidebarVisible = !this.sidebarVisible;
      this.mobileMenu = !this.mobileMenu;
      this.subItem1 = [];
      this.megaItem1 = false;
      this.megaItem2 = false;
    
    },
    
    hideSidebar() {
      // Hide the sidebar
      this.sidebarVisible = false;

    },
    ToogleSub1(index) {
      this.subItem1[index] = !this.subItem1[index];
    },
    ToogleNega1() {
      //this.megaItem1 = !this.megaItem1;
      this.subItem1 = this.subItem1 === index ? null : index;
    },
    ToogleSub2() {
      this.subItem2 = !this.subItem2;
    },
    convertToMenuStructure(jsonData) {
      const result = [];
      jsonData.forEach((category) => {
        const categoryItem = {
          label: category.name,
          icon: "pi pi-box",
          items: [],
          command: () => {},
        };
        category.result.forEach((subcategory) => {
          const subcategoryItem = {
            label: subcategory._id,
            items: [],
          };
          subcategory.data.forEach((subSubcategory) => {
            const subSubcategoryItem = {
              label: subSubcategory._id.brand,
            };
            subcategoryItem.items.push(subSubcategoryItem);
          });
          categoryItem.items.push([subcategoryItem]);
        });
        result.push(categoryItem);
      });
      return result;
    },
    GetMenu() {
      var data = { count: false };
      var promise = apis.GetEcommerceMenu(data);
      promise.then((response) => {
        this.products = response.data.data;
        this.isCollapsed = [];
        for (let index = 0; index < this.products.length; index++) {
          this.isCollapsed.push(true);
          for (let i = 0; i < this.products[index].result.length; i++) {
            this.isCollapsed_sub.push(true);
          }
        }
        // var temp = response.data.data;
        // this.products = this.convertToMenuStructure(temp);
      });
    },
    gotohome() {
      this.$router.push("/");
    },
    goTo(L1) {
      if (L1.name == "Brand") {
        this.$router.push({ name: "pphbrand" });
      } else if (L1.name == "Home") {
        this.$router.push("/");
      }
    },
    updateSuggestions(event) {
      //this.$refs.notification.toggle(event);
      this.suggestions = [];
      var data = {
        count: false,
        search: this.searchQuery,
        limit: 10,
        page_no: 1,
      };
      this.loading = true;
      var promise = apis.GetProductList(data);
      promise.then((response) => {
        this.loading = false;
        if (this.searchQuery != "") {
          this.suggestions = response.data.data;
          if (this.suggestions.length > 0) {
            this.isSearchActive = true;
          } else {
            this.isSearchActive = false;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.arrow {
  border: none;
  float: right;
  /* padding-left: 50px; */
  background-color: #efefef;
}
.button.arrow {
  border: none;
  float: right;
}
.toggle-button {
  background-color: #efefef;
  border: 1px solid #ccc;
  padding: 10px 6px;
  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 5px 0;
  list-style: none;
  margin: 0;
  display: none;
}
.dropdown-menu li {
  padding: 5px 15px;
  cursor: pointer;
}
.dropdown-menu li:hover {
  background-color: #f8f9fa;
}
.dropdown-menu.show {
  display: block;
}
.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
  background-color: var(--bs-tertiary-bg);
}
.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
  position: absolute;
  padding-left: 70%;
  margin-bottom: 20px;
}
[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}
.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}
.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-tertiary-bg);
}
.scrollarea {
  overflow-y: auto;
}
.dropdown-toggle {
  outline: 0;
}
/* 
  .btn-toggle[aria-expanded="true"]::before {
      transform: rotate(90deg);
  } */
.collapse:not(.show) {
  display: none;
}
ul,
li {
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
}
a {
  text-decoration: none;
}
.header-area {
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.btn-blue {
  background-color: #2e3192;
  color: #fff;
  width: 120px;
  height: 50px;
}
.form-control {
  width: 500px;
  background-color: #eee;
}
.logo {
  width: 300px;
  font-size: 30px;
  font-weight: 600;
}
.navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: flex-end; */
  padding-bottom: 1rem;
}
.menu-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}
.marginauto {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: start;
}
.menu-area > ul {
  width: 100%;
}
.menu-area li {
  display: inline-block;
}
.menu-area li a {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #000;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.subIocn {
  position: relative;
  display: inline-block;
}
.subIocn::before {
  position: absolute;
  display: block;
  content: "";
  left: -12px;
  top: -10px;
  width: 6px;
  height: 6px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sub li {
  display: block;

    width: 250px;
    padding-left: 20px;
    padding-top: 20px;
}
.sub li a {
  display: block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 8px 15px;
}
.sub li a:hover {
  background: #f5f5f5;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sub {
  display: none;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 100%;
  width: 100%;
  min-width: 10rem;
  max-width: 12rem;
  background: #fff;
  /* border-radius: 6px; */
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 6;
}
.mega {
  display: none;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 100%;
  width: auto;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 90%;
  background: #fff;
  /* border-radius: 6px; */
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 6;
}
.menu-area li:hover .sub {
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.menu-area li:hover .mega {
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mega-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px;
}
.mega li {
  display: block;
}
.mega-item {
  width: 25%;
}
.mega-item h3 {
  font-size: 15px;
  line-height: 1.3;
  font-weight: 600;
}
.mega-menu li {
  display: block;
}
.mega-menu li a {
  display: inline-block;
  padding: 8px 0;
}
/*----mobile view---*/
.mega1 {
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 100%;
  width: auto;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 90%;
  background: #fff;
  /* border-radius: 6px; */
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 6;
}
.menu-area li:hover .sub {
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.menu-area li:hover .mega1 {
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mega1-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px;
}
.mega1 li {
  display: block;
}
.mega1-item {
  width: 25%;
}
.mega1-item h3 {
  font-size: 15px;
  line-height: 1.3;
  font-weight: 600;
}
.mega1-menu li {
  display: block;
}
.mega1-menu li a {
  display: inline-block;
  padding: 8px 0;
}
/*----end---*/
.mobilebtn {
  display: none;
  border: none;
  font-size: 1.5rem;
}
/*--sidebar mobile view--*/
.logo > img {
  padding-left: 40px;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  z-index: 999;
  backdrop-filter: blur(10px);
  background-color: #efefef;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 12px;
  padding-top: 30px;
  transition: 0.5s;
  line-height: 31px;
  overflow-y: scroll;
}
.sidebar li {
  width: 100%;
}
.sidebar a {
  width: 100%;
}
.view {
  list-style: none;
}
.subIocn1 {
  position: relative;
  display: inline-block;
}
.subIocn1::before {
  position: absolute;
  display: block;
  content: "";
  left: 12px;
  top: -10px;
  width: 6px;
  height: 6px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hidebar {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  /* .sidebar{
    width: 39%;
  } */
  .hideOnMobile {
    display: none;
  }
  .mobilebtn {
    display: block;
  }
}
.mega {
  display: none;
}
a.title {
  font-size: 15px;
  font-weight: 600;
}
.title:hover {
  color: #0f75bc;
}
@media (max-width: 1199.98px) {
  .logo {
    width: auto;
    font-size: 20px;
    font-weight: 600;
  }
  .mobilebtn {
    display: block;
  }
  .sub-menu {
    text-transform: lowercase;
    text-align: left;
  }
  .menu-area {
    display: none;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .menu-area.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-box-flex: 100% !important;
    -ms-flex-positive: 100% !important;
    flex-grow: 100% !important;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .marginauto {
    display: block;
  }
  .menu-area li {
    display: block;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
  }
  .sub,
  .mega {
    position: static;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: none !important;
  }
  .sub.active {
    display: block !important;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .mega.active {
    display: block !important;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .menu-area a {
    font-size: 15px;
    font-weight: 600;
  }
  .menu-area li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    color: #000;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    border-top: 1px solid #ccc;
  }
  .menu-area li:first-child a {
    border-top: 1px solid transparent;
  }
  .sub li a {
    padding: 8px 15px !important;
  }
  .mega-menu li a {
    padding: 8px 0 !important;
  }
  .subIocn {
    position: absolute;
    top: 6px;
    right: 4px;
    z-index: 9;
    display: inline-block;
    /* border: 1px solid #ccc; */
    padding: 5px;
    height: 22px;
    width: 22px;
  }
  .subIocn::before {
    left: 0;
    right: 0;
    top: 9px;
    margin: 1px auto;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .subIocn.active::before {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 9px;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  .mega-item {
    width: 33.33%;
  }
}
@media only screen and (min-width: 1200px) {
  .sidebar {
    width: 22%;
  }
}
@media (min-width: 991.98px) {
  /* .mega-item {
    width: 50%;
  } */
  .sub-menu {
    text-transform: lowercase;
    text-align: left;
  }
  /* .mega {
    height: 300px;
    overflow-y: scroll;
  } */
  .sidebar {
    width: 27%;
  }
}
@media (max-width: 767.98px) {
  .mega-item {
    width: 100%;
  }
  .sub-menu {
    text-transform: lowercase;
    text-align: left;
  }
  .sidebar {
    width: 27%;
  }
}
@media only screen and (max-width: 600px) {
  .logo img {
    padding-left: 20px;
  }


  .drop {
    align-items: end;
  }
  .title1 {
    font-size: 20px;
    font-weight: 600;
  }
  .sub li {
    display: block;
  }
  .sub li a {
    display: block;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 8px 15px;
  }
  .sub li a:hover {
    background: #f5f5f5;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .sub1 {
    display: none;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 100%;
    width: 100%;
    min-width: 10rem;
    max-width: 12rem;
    background: #fff;
    /* border-radius: 6px; */
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    z-index: 6;
  }
  .mega {
    display: none;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 100%;
    width: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 90%;
    background: #fff;
    /* border-radius: 6px; */
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    z-index: 6;
  }
  .logo {
    display: flex;
    justify-content: center;
  }


  /* .sidebar{
    width: 39%;
  } */
  .form1 {
    width: 100%;
  }
  .form-control {
    width: 100%;
  }
  .pphlogo {
    width: 100px;
    margin-top: 2px;
  }
  .arrow {
    padding-left: 118px;
  }

  .sidebar {
    width: 80%;
  }
}
.navbar2 {
  display: flex;
  justify-content: space-evenly;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 100;
}
/* @media only screen and (max-width: 480px) {
  .arrow {
padding-left: 52px;
}

} */

@media only screen and (max-width: 400px) {
  .arrow {
padding-left: 52px;
}
}


@media  screen and (min-width: 1024px){
  .arrow {
padding-left: 70px;
}

}
@media  screen and (max-width: 768px) {
  .form1 {
    width: 100%;
  }
  .form-control {
    width: 100%;
  }
  .sub-menu {
    text-transform: lowercase;
    text-align: left;
  }


}


.hover:hover {
  /* background-color: #F2F2F2; */
  color: red;
}
.item:hover {
  color: darkcyan;
}
/*======================================================================= */
.searchInput {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}
.searchInput input {
  height: 35px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 14px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.searchInput.active input {
  border-radius: 5px 5px 0 0;
}
.searchInput .resultBox {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  max-height: 600px;
  overflow-y: auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  z-index: 1;
}
.searchInput.active .resultBox {
  padding: 10px 8px;
  opacity: 1;
  pointer-events: auto;
}
.resultBox li {
  list-style: none;
  padding: 8px 12px;
  display: none;
  width: 100%;
  cursor: default;
  border-radius: 3px;
}
.searchInput.active .resultBox li {
  display: block;
}
.resultBox li:hover {
  background: #efefef;
}
.searchInput .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  color: #644bff;
  cursor: pointer;
}
</style>