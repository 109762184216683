<template>
  <footer style="background-color: black">
    <div class="container">
      <div class="row align-items-star">
        <div class="col-md-3">
          <h4>PPH</h4>
          <p>
            PPH.in (PCPT) - Pricing information of various computer components &
            related peripherals
          </p>
          <!-- <a href="https://www.amazon.in/HP-21-5-inch-Three-Sided-Micro-Edge-22-dd2686in/dp/B0BCH42CXW?crid=1R4ZL9578Y9AO&dib=eyJ2IjoiMSJ9.RrLPuSw8rJERUtxy_3PN8Rp5bjhDZhyTGMzI1dsgVMEeNkF-8NtHFDD5a7dAWwWEwNzsi5vGvkYsOkwwh-mc-7wVseWPPJUmVrEVRq2ERPHtTbZcokQem6PY7EBgPhksbAhVjK5cBtMB0nTZRApVbLnimPip1Bgslm5q9c-I5IcLlBvtcc58q7CLZZCHu5iRS3Lbvkf1YJxlcClLXWBukN4EDTMryJLMCRHmkoLoskuMquYoUyetUlujkQQFcEznStAJTB8fked0o-k0ZtcF0oyIxxzjZGDwEbgae_j6j9Q.njATU8psv4ulTpthEGeQag7ROeHLCVXkbd8VUOEaruc&dib_tag=se&keywords=computer&qid=1709712785&s=computers&sprefix=%2Ccomputers%2C203&sr=1-2&linkCode=ll1&tag=pph0f-21&linkId=2c7c43f742894b8bec2da5ef3583d026&language=en_IN&ref_=as_li_ss_tl">
            Link
          </a> -->
        </div>

        <div class="col-md-3 mt-2">
          <h4>COMPANY</h4>
          <p style="cursor: pointer;" @click="GoTo('About')">About</p>
          <!-- <p style="cursor: pointer;">Roadmap</p> -->
          <!-- @click="GoTo('Roadmap')" -->
        </div>
        <div class="col-md-3 mt-2">
          <h4>USEFUL LINK</h4>
          <!-- <p style="cursor: pointer;" >Term & Condition</p> -->
          <!-- @click="GoTo('Term_Condition')" -->
          <p style="cursor: pointer;" @click="GoTo('PrivacyPolicy')">Privacy policy</p>
          <!-- <a href="https://www.amazon.in/HP-21-5-inch-Three-Sided-Micro-Edge-22-dd2686in/dp/B0BCH42CXW?crid=1R4ZL9578Y9AO&dib=eyJ2IjoiMSJ9.RrLPuSw8rJERUtxy_3PN8Rp5bjhDZhyTGMzI1dsgVMEeNkF-8NtHFDD5a7dAWwWEwNzsi5vGvkYsOkwwh-mc-7wVseWPPJUmVrEVRq2ERPHtTbZcokQem6PY7EBgPhksbAhVjK5cBtMB0nTZRApVbLnimPip1Bgslm5q9c-I5IcLlBvtcc58q7CLZZCHu5iRS3Lbvkf1YJxlcClLXWBukN4EDTMryJLMCRHmkoLoskuMquYoUyetUlujkQQFcEznStAJTB8fked0o-k0ZtcF0oyIxxzjZGDwEbgae_j6j9Q.njATU8psv4ulTpthEGeQag7ROeHLCVXkbd8VUOEaruc&dib_tag=se&keywords=computer&qid=1709712785&s=computers&sprefix=%2Ccomputers%2C203&sr=1-2&linkCode=ll1&tag=pph0f-21&linkId=2c7c43f742894b8bec2da5ef3583d026&language=en_IN&ref_=as_li_ss_tl">
            social links
          </a> -->
        </div>
        <div class=" col-md-3  mt-2">
          <h4></h4>
          <a href="#"><p>
            pcparthunt2023@gmail.com
          </p></a>
        </div>
        <p class="text-center pt-6 ">copyright 2024 &copy; PPH</p>
      </div>

    </div>
    
  </footer>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
    GoTo(data)
      {
        this.$router.push({name: data});
      }

  }
};
</script>
<style scoped >
footer{
    color:#fff;
bottom:0;

width:100%;
    padding: 1rem 1rem 1.75rem 1rem;
}
.p-megamenu-panel .p-menuitem-link .p-menuitem-text {
  color: red !important;
  font-size: 20;
}

h4{
  font-size:14px;
}

  
.p-megamenu .p-megamenu-submenu-header{
    font-size: 20px !important;
  }

</style>