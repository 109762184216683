<template>
  <div class="container">
    <div class="searchInput" :class="{ active: isSearchActive }">
      <input
        v-model="searchQuery"
        @input="handleInput"
        type="text"
        placeholder="Search.."
      />
      <div class="resultBox">
        <ul v-if="isSearchActive">
          <li
            v-for="(suggestion, index) in suggestions"
            :key="index"
            @click="select(suggestion)"
          >
            {{ suggestion }}
          </li>
        </ul>
      </div>
      <div class="icon"><i class="fas fa-search"></i></div>
    </div>
  </div>
</template>
  
  <script>
import apis from "@/apis";
export default {
  data() {
    return {
      searchQuery: "",
      isSearchActive: false,
      suggestions: [],
    };
  },
  computed: {},
  methods: {
    handleInput() {
      this.suggestions = [];
      var data = {
        count: false,
        title: this.searchQuery,
        limit: 10,
        page_no: 1,
      };
      var promise = apis.GetProductList(data);
      promise.then((response) => {
        if (this.searchQuery != "") {
          this.suggestions = response.data.data;
          if (this.suggestions.length > 0) {
            this.isSearchActive = true;
          } else {
            this.isSearchActive = false;
          }
        }
      });
    },
  },
};
</script>
  
  <style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

::selection {
  color: #fff;
  background: #664aff;
}

.searchInput {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}

.searchInput input {
  height: 55px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.searchInput.active input {
  border-radius: 5px 5px 0 0;
}

.searchInput .resultBox {
  padding: 0;
  opacity: 0;
  pointer-events: none;
  max-height: 280px;
  overflow-y: auto;
}

.searchInput.active .resultBox {
  padding: 10px 8px;
  opacity: 1;
  pointer-events: auto;
}

.resultBox li {
  list-style: none;
  padding: 8px 12px;
  display: none;
  width: 100%;
  cursor: default;
  border-radius: 3px;
}

.searchInput.active .resultBox li {
  display: block;
}
.resultBox li:hover {
  background: #efefef;
}

.searchInput .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  color: #644bff;
  cursor: pointer;
}
</style>
  